import requestService from "../requestService";
import store from '@/store';

export default {
	async createSetting(form) {
		const response = await requestService.post(`/admin/settings`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async updateSetting(id, form) {
		const response = await requestService.post(`/admin/settings/${id}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getSettingList() {
		const response = await requestService.get(`/settings/list?list=common,dispatchers_phone_number,contact,socials`)
		return response?.data
	},
	async getSettingListByType(type) {
		const response = await requestService.get(`/settings/${type}`)
		return response?.data
	},
	async getSettingListForAdminByType(type) {
		const response = await requestService.get(`/admin/settings/${type}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getSettingListForAdmin() {
		const response = await requestService.get(`/admin/settings`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getSetting(id) {
		const response = await requestService.post(`/setting/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async deleteSetting(id) {
		const response = await requestService.delete(`/admin/settings/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	}
}