<template>
  <v-app-bar height="112px" max-height="122px" top color="#F8F8F8">
    <v-main>
      <v-container>
        <v-row no-gutters align="center" v-if="!$vuetify.breakpoint.smAndDown">
          <v-col :cols="loggedUser ? '6' : '5'">
            <v-row no-gutters align="center">
              <router-link
                v-for="item in headerItems"
                :key="item.id"
                :to="item.path"
                v-show="loggedUser ? item : item.visibleForUser == true"
              >
                <span
                  class="headerItem"
                  :class="$route.path == item.path ? 'activeHeaderItem' : ''"
                  >{{ item.name }}</span
                >
              </router-link>
            </v-row>
          </v-col>
          <v-col :cols="loggedUser ? '2' : '3'" style="text-align: center">
            <img
              src="@/assets/img/logo.png"
              width="200px"
              height="72px"
              alt="logo"
            />
          </v-col>
          <v-col cols="4" style="text-align: right">
            <v-row no-gutters align="center" justify="end">
              <a
                v-for="(link, index) in socialLinks"
                :key="link.id"
                target="_blank"
                class="icon"
                :href="link.value"
                :style="
                  index == socialLinks.length - 1 ? 'margin-right: 0px' : ''
                "
              >
                <img
                  width="24px"
                  height="24px"
                  :src="link.image.path"
                  :alt="link.image.path"
                  style="object-fit: contain"
                />
              </a>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="end"
              style="margin-top: 24px"
              :key="key"
            >
              <a
                :href="`tel: ${number.value}`"
                v-for="(number, index) in phoneNumbers.slice(0, 2)"
                :key="number.id"
              >
                <span
                  class="headerItem"
                  :style="
                    phoneNumbers.length > 2
                      ? index == 1
                        ? 'margin-right: 0px'
                        : ''
                      : 'margin-right: 0px'
                  "
                  >{{ number.value }}</span
                >
              </a>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters v-else align="center" justify="end">
          <v-col cols="10" style="text-align: center">
            <img
              src="@/assets/img/logo.png"
              width="140px"
              height="50px"
              alt="logo"
              style="margin-left: 22px"
            />
          </v-col>
          <img
            src="@/assets/img/mobileIcon/callCenterIcon.svg"
            width="20px"
            height="20px"
            style="z-index: 20; position: absolute; margin-right: 9.5px"
            alt="call center"
            @click="$emit('showSocialDrawer')"
          />
          <div class="backgroundIcon" @click="$emit('showSocialDrawer')"></div>
        </v-row>
      </v-container>
    </v-main>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "headerComponent",
  data: () => ({
    headerItems: [
      {
        id: 1,
        name: "Головна",
        path: "/",
        visibleForUser: true,
      },
      {
        id: 4,
        name: "Маршрути",
        path: "/routes",
        visibleForUser: true,
      },
      {
        id: 2,
        name: "Автопарк",
        path: "/car_park",
        visibleForUser: true,
      },
      {
        id: 3,
        name: "Контакти",
        path: "/contact",
        visibleForUser: true,
      },
      {
        id: 5,
        name: "Адмін",
        path: "/admin",
        visibleForUser: false,
      },
    ],
    socialLinks: [],
    phoneNumbers: [],
    key: 0,
  }),
  mounted() {
    this.setLists();
  },
  methods: {
    setLists() {
      this.phoneNumbers = this.settingList.filter(
        (setting) => setting.type == "contact"
      );
      this.socialLinks = this.settingList.filter(
        (setting) => setting.type == "socials"
      );
      this.key++;
      console.log(this.key)
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["settingList"]),
  },
  watch: {
    settingList: {
      deep: true,
      handler() {
        console.log("work")
        this.setLists();
      },
    },
  },
};
</script>

<style scoped>
.headerItem {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #1b1b1b;
  margin-right: 32px;
  border-bottom: 1px solid transparent;
}
.headerItem:nth-child(4) {
  margin-right: 0px;
}
.headerItem:hover {
  color: #cc922f;
}
.activeHeaderItem {
  color: #cc922f;
  border-bottom: 1px solid #cc922f;
}
.icon {
  margin-right: 32px;
}
.backgroundIcon {
  width: 40px;
  height: 40px;
  background: #0f1f2f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  filter: blur(0.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
</style>