<template>
  <div class="bottomMenu">
    <router-link :to="item.href" v-for="item in menuItems" :key="item.id">
      <div v-if="item.href !== $route.path">
        <div :class="item.icon" style="margin-right: 30px" />
      </div>
      <div
        v-else
        :style="item.id == 4 ? 'margin-right: 0px' : 'margin-right: 30px'"
      >
        <div class="activeItem">
          <div :class="item.icon" />
          <span class="activeItemText">{{ item.title }}</span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  data: () => ({
    menuItems: [
      {
        id: 1,
        icon: "mainIcon",
        title: "Головна",
        href: "/",
      },
      {
        id: 2,
        icon: "routeIcon",
        title: "Маршрути",
        href: "/routes",
      },
      {
        id: 3,
        icon: "carParkIcon",
        title: "Автопарк",
        href: "/car_park",
      },
      {
        id: 4,
        icon: "contactIcon",
        title: "Контакти",
        href: "/contact",
      },
    ],
  }),
};
</script>

<style scoped>
.bottomMenu {
  height: 80px;
  background: #fcfcfc;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 0px 0px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 20px;
  z-index: 100;
  width: 100%;
}
.mainIcon {
  width: 28px;
  height: 28px;
  background-color: #0f1f2f;
  -webkit-mask: url("@/assets/img/mobileIcon/mainIcon.svg") no-repeat center;
  mask: url("@/assets/img/mobileIcon/mainIcon.svg") no-repeat center;
}
.routeIcon {
  width: 28px;
  height: 28px;
  background-color: #0f1f2f;
  -webkit-mask: url("@/assets/img/mobileIcon/routesIcon.svg") no-repeat center;
  mask: url("@/assets/img/mobileIcon/routesIcon.svg") no-repeat center;
}
.carParkIcon {
  width: 28px;
  height: 28px;
  background-color: #0f1f2f;
  -webkit-mask: url("@/assets/img/mobileIcon/carParkIcon.svg") no-repeat center;
  mask: url("@/assets/img/mobileIcon/carParkIcon.svg") no-repeat center;
}
.contactIcon {
  width: 28px;
  height: 28px;
  background-color: #0f1f2f;
  -webkit-mask: url("@/assets/img/mobileIcon/contactIcon.svg") no-repeat center;
  mask: url("@/assets/img/mobileIcon/contactIcon.svg") no-repeat center;
}
.activeItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: max-content;
  width: 100%;
  height: 52px;
  background: #0f1f2f;
  border-radius: 20px;
  animation: resizeAnimation 0.5s ease-in-out forwards;
}
@keyframes resizeAnimation {
  0% {
    width: 80%;
  }
  100% {
    width: 100%;
  }
}
.activeItem .mainIcon {
  background-color: #cc922f;
  margin-right: 8px;
}
.activeItem .routeIcon {
  background-color: #cc922f;
  margin-right: 8px;
  margin-left: 0px;
}
.activeItem .carParkIcon {
  background-color: #cc922f;
  margin-right: 8px;
  margin-left: 0px;
}
.activeItem .contactIcon {
  background-color: #cc922f;
  margin-right: 8px;
  margin-left: 0px;
}
.activeItemText {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #cc922f;
}
</style>