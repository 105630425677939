<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    width="100%"
    style="z-index: 100 !important"
  >
    <div class="drawerBackground">
      <v-row no-gutters justify="end">
        <img
          src="@/assets/img/mobileIcon/closeIcon.svg"
          alt="close icon"
          @click="$emit('close')"
        />
      </v-row>
      <v-col cols="12" class="px-0 py-0" style="margin-top: 20px">
        <p class="drawerSectionTitle">
          Слідкуйте за нами та дізнавайтеся актуальну інформацію
        </p>
        <a
          :href="social.value"
          target="_blank"
          v-for="social in socialLinks"
          :key="social.id"
        >
          <v-row no-gutters align="center" style="margin-bottom: 20px">
            <img
              :src="social.image.path"
              width="24px"
              height="24px"
              alt="social icon"
            />
            <span class="drawerSectionText" style="margin-left: 12px">{{
              social.key
            }}</span>
            <v-row no-gutters justify="end" align="center">
              <v-icon color="#1B1B1B" size="22px">mdi-chevron-right</v-icon>
            </v-row>
          </v-row>
        </a>
        <p class="drawerSectionTitle" style="margin-top: 40px">
          Служба підтримки клієнтів
        </p>
        <p
          class="drawerSectionText"
          style="width: 100%; text-align: center"
          v-for="phone in phoneNumbers"
          :key="phone.id"
        >
          <a :href="`tel: ${phone.value}`" style="color: #1b1b1b">
            {{ phone.value }}
          </a>
        </p>
        <v-row no-gutters justify="center">
          <img
            src="@/assets/img/logo.png"
            width="140px"
            height="50px"
            style="margin-top: 100px"
            alt="logo"
          />
        </v-row>
      </v-col>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    drawer: true,
    phoneNumbers: [],
    socialLinks: [],
  }),
  mounted() {
    this.setPhoneNumbers();
  },
  methods: {
    setPhoneNumbers() {
      this.phoneNumbers = this.settingList.filter(
        (setting) => setting.type == "dispatchers_phone_number"
      );
      this.socialLinks = this.settingList.filter(
        (setting) => setting.type == "socials"
      );
    },
  },
  computed: {
    ...mapGetters(["settingList"]),
  },
  watch: {
    drawer: {
      handler() {
        if (this.drawer == false) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>

<style scoped>
.drawerBackground {
  background: #f8f8f8;
  padding: 20px;
  height: 100%;
  width: 100%;
}
.drawerSectionTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #cc922f;
  margin-bottom: 32px;
}
.drawerSectionText {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #1b1b1b;
}
</style>