import requestService from "../requestService";
import store from "@/store";
import router from "@/router";

export default {
	async signUp(form) {
		const response = await requestService.post(`/register`, form)
		return response?.data
	},
	async signIn(form) {
		const response = await requestService.post(`/login`, form)
		return response?.data
	},
	async refreshToken() {
		var form = new FormData();
		form.append("token", store.getters.loggedUser.token);
		let res = requestService.post(`/refresh`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		}).then((response) => {
			let result = response?.data.authorisation;
			store.dispatch('updateInfoLogged', {
				token: result.token
			})
			return response
		})
			.catch(() => {
				store.commit('clearUserLogged')
				router.push('/')
			})
		return res
	},
	async resetPassword(form) {
		const response = await requestService.post(`/resetRequest`, form)
		return response?.data
	},
	async getUsers(page) {
		const response = await requestService.get(`/admin/users?page=${page}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async createUser(form) {
		const response = await requestService.post(`/admin/user`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async updateUser(id, form) {
		const response = await requestService.post(`/admin/user/${id}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async changePassword(form) {
		const response = await requestService.post(`/changePassword`, form)
		return response?.data
	},
	async changeUserPassword(form) {
		const response = await requestService.post(`/update_password`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	}
}