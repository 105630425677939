<template>
  <v-app>
    <Header @showSocialDrawer="isShowSocialDrawer = true" />
    <router-view />
    <Footer />
    <mobile-bottom-menu v-if="$vuetify.breakpoint.smAndDown && !isShowSocialDrawer" />
    <mobile-navigation-drawer
      v-if="isShowSocialDrawer"
      @close="isShowSocialDrawer = false"
    />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import MobileBottomMenu from "./components/User/mobileBottomMenu.vue";
import MobileNavigationDrawer from "./components/User/mobileNavigationDrawer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    MobileBottomMenu,
    MobileNavigationDrawer,
  },
  data: () => ({
    isShowSocialDrawer: false,
  }),
  mounted() {
    this.getSettingList();
  },
  methods: {
    ...mapActions(["getSettingList"]),
  },
};
</script>

<style>
#app {
}
a {
  text-decoration: none;
  color: #1b1b1b;
}
</style>
